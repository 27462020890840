import { api } from "utils/api";

const baseDashboardUrl = `https://${process.env.REACT_APP_ENV}-dashboard.${process.env.REACT_APP_API_URL}/dashboard`;

export const getMyStakes = (params: string) => api.get(`${baseDashboardUrl}/mystakes/list/${params}`);
export const getMyBounties = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/mybounties/list`, params);
export const getMyReports = (params: string) => api.get(`${baseDashboardUrl}/myreports/list`, params);
export const getMyReport = (reportId: string) => api.get(`${baseDashboardUrl}/myreports/${reportId}`);

export const createReport = (params: Record<string, unknown>) => api.post(`${baseDashboardUrl}/reports`, params);
export const updateReport = (params: Record<string, unknown>) => api.put(`${baseDashboardUrl}/reports`, params);
export const getMyContracts = (params: string) => api.get(`${baseDashboardUrl}/mycontracts/list/${params}`);

export const createReportActivity = (params: Record<string, unknown>) =>
  api.post(`${baseDashboardUrl}/report-activities`, params);
export const updateReportActivity = (params: Record<string, unknown>) =>
  api.put(`${baseDashboardUrl}/report-activities`, params);

export const getReportActivities = (reportId: string) =>
  api.get(`${baseDashboardUrl}/report-activities/list/${reportId}`);
export const getReportActivity = (reportActivityId: string) =>
  api.get(`${baseDashboardUrl}/report-activities/${reportActivityId}`);
export const getMyReportActivities = (reportId: string) =>
  api.get(`${baseDashboardUrl}/my-report-activities/list/${reportId}`);

export const deleteReport = (reportId: string) => api.delete(`${baseDashboardUrl}/reports/${reportId}`);
export const deleteReportActivity = (reportActivityId: string) =>
  api.delete(`${baseDashboardUrl}/report-activities/${reportActivityId}`);

// Organization reports
export const getOrganizationReports = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/myreports/client/list`, params);

export const getOrganizationReport = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/myreports/client/${params.reportId}`);

export const updateOrganizationReport = (params: Record<string, unknown>) =>
  api.put(`${baseDashboardUrl}/myreports/client`, params);

export const getOrganizationReportActivities = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/my-report-activities/client/list/${params.reportId}`);

export const getVdm = (hourlyRate: string) => api.get(`${baseDashboardUrl}/vdm-formula/${hourlyRate}`);

export const getBountiesRequests = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/mybounties/request/list`, params);
export const getBountiesHistoryRequests = (params: Record<string, unknown>) =>
  api.get(`${baseDashboardUrl}/mybounties/request_history/list`, params);
export const claimBounty = (params: Record<string, unknown>) =>
  api.post(`${baseDashboardUrl}/mybounties/request`, params);
export const updateBountyStatus = (params: Record<string, unknown>) =>
  api.put(`${baseDashboardUrl}/mybounties/request`, params);

export const getBountyStatuses = () => api.get(`${baseDashboardUrl}/mybounties/statuses/list`);
export const getBountyTypes = () => api.get(`${baseDashboardUrl}/mybounties/types/list`);
export const getMembersList = () => api.get(`${baseDashboardUrl}/members/list`);

export const getAdminReports = (params: string) => api.get(`${baseDashboardUrl}/myreports/admin/list`, params);
export const getAdminReport = (reportId: string) => api.get(`${baseDashboardUrl}/myreports/admin/${reportId}`);
export const addReportPayment = (params: Record<string, unknown>) =>
  api.post(`${baseDashboardUrl}/myreports/admin/report-payment`, params);

import { api } from "utils/api";

const baseAuthUrl = `https://${process.env.REACT_APP_ENV}-auth.${process.env.REACT_APP_API_URL}/auth`;

export const registerUser = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/register`, params);

export const loginUser = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/login`, params);

export const resetPassword = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/resetpassword`, params);

export const resetUserPassword = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/reset`, params);

export const changePassword = (params: Record<string, string>) => api.post(`${baseAuthUrl}/changepassword`, params);

export const deactivateUser = (params: Record<string, string>) => api.post(`${baseAuthUrl}/deactivate`, params);

export const activateUser = (params: Record<string, string>) => api.post(`${baseAuthUrl}/activate`, params);

export const listInvitedMember = (params: Record<string, unknown>) => api.get(`${baseAuthUrl}/invites`, params);

export const inviteMember = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/invites`, params);

export const deleteInvite = (inviteId: number) => api.delete(`${baseAuthUrl}/invites/${inviteId}`);

export const getInviteDetails = (inviteCode: string) => api.get(`${baseAuthUrl}/invite/register/${inviteCode}`);
export const registerInvite = (params: Record<string, unknown>) => api.post(`${baseAuthUrl}/invite/register`, params);
